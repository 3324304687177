@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    /* ... other variables ... */
  }

  .dark {
    /* dark mode variables */
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) translateX(0);
  }
  25% {
    transform: translateY(-15px) translateX(15px);
  }
  50% {
    transform: translateY(-25px) translateX(-15px);
  }
  75% {
    transform: translateY(-15px) translateX(-25px);
  }
}

@keyframes draw {
  0% {
    stroke-dashoffset: 1000;
    fill-opacity: 0;
  }
  50% {
    fill-opacity: 0;
  }
  100% {
    stroke-dashoffset: 0;
    fill-opacity: 1;
  }
}

@keyframes reveal-line {
  0% {
    height: 0;
    opacity: 0;
  }
  50% {
    height: 100%;
    opacity: 0.5;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}

.animate-float {
  animation: float 5s infinite ease-in-out;
}

.animate-draw {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  stroke: currentColor;
  stroke-width: 1;
  animation: draw 3s forwards ease-out;
}

.animate-reveal-line {
  animation: reveal-line 3s infinite ease-in-out;
}

.animate-fade-in {
  opacity: 0;
  animation: fadeIn 2s forwards ease-out;
  animation-delay: 1.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
